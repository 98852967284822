
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useState } from 'react'

import { Button, BodyText1, BodyText2, BodyText3, Heading1 } from '@lumoslabs/lumosity-storybook'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

import MobileDevicesInterstitial from '~/components/MobileDevicesInterstitial'
import { LUMOSITY_USER_COOKIE } from '~/constants'
import { currentUserVar } from '~/graphql/reactive-vars'
import useIsMobileDevice from '~/hooks/useIsMobileDevice'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { logToRollbar } from '~/utils/errorUtils'
import { parseUser } from '~/utils/loginUtils'

const { publicRuntimeConfig } = getConfig()

export const ResearchLoginForm = () => {
  const t = useTranslationForNamespace('login')

  const loginErrorMessage = t('failedLoginMessaging')
  const offlineErrorMessage = t('offlineErrorMessage', {
    fallback: 'Sorry, we were unable to connect to the server. Please check your internet connection and try again.',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [usernameEntered, setUsernameEntered] = useState<boolean>(false)
  const [passwordEntered, setPasswordEntered] = useState<boolean>(false)
  const disabled = !(usernameEntered && passwordEntered)
  const router = useRouter()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setCookie] = useCookies([LUMOSITY_USER_COOKIE, 'flow'])

  const usernameText = t('username')
  const passwordText = t('password')

  const minimumPasswordLength = 4
  const minimumUsernameLength = 5

  const loginEndpoint = publicRuntimeConfig.login.endpoint

  const welcomeText = t('welcome')

  const handleUsernameInput = async (event: React.SyntheticEvent) => {
    // remove error
    setErrorMessage('')
    // Make typescript happy https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events/
    const target = event.target as typeof event.target & {
      value: string
    }
    setUsernameEntered(target.value.length > 0)
  }

  const handlePasswordInput = async (event: React.SyntheticEvent) => {
    // remove error
    setErrorMessage('')
    // Make typescript happy https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events/
    const target = event.target as typeof event.target & {
      value: string
    }
    setPasswordEntered(target.value.length > 0)
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // Stop the form from submitting and refreshing the page.
    event.preventDefault()

    // Make typescript happy https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events/
    const target = event.target as typeof event.target & {
      username: { value: string }
      password: { value: string }
    }

    // Get data from the form.
    const data = {
      username: target.username.value,
      password: target.password.value,
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    const response = await fetch(loginEndpoint, options)
      .then((retrievedresponse) => {
        if (retrievedresponse.status === 401) {
          setErrorMessage(loginErrorMessage)
        } else if (Math.floor(retrievedresponse.status / 100) !== 2) {
          throw retrievedresponse.statusText
        } else {
          return retrievedresponse
        }
      })
      .catch((error) => {
        setErrorMessage(offlineErrorMessage)
        logToRollbar('Error logging in user from login fetch:\n' + JSON.stringify({ error }))
      })

    if (response && response.status !== 401) {
      const result = await response.text()
      if (result && response.status !== 400) {
        setErrorMessage('')
        const JWT = await result

        setCookie(LUMOSITY_USER_COOKIE, JWT, {
          path: '/',
          sameSite: true,
        })

        const user = parseUser(JWT)

        // set user reactive variable or redirect if user can't be parsed
        if (!user) {
          user && window?.Rollbar?.error('Error parsing user from JWT', { JWT })
        } else {
          currentUserVar(user)
        }
        router.push('/')
      } else {
        setErrorMessage(offlineErrorMessage)
        logToRollbar('Error logging in user:\n' + JSON.stringify({ response }))
      }
    }
  }

  const isMobileDevice = useIsMobileDevice()

  if (isMobileDevice) {
    return <MobileDevicesInterstitial />
  }

  return (
    <PageContainer>
      <Heading>{welcomeText}</Heading>
      <Subheader>{t('subheader')}</Subheader>
      <Form onSubmit={handleSubmit}>
        <InputText>
          <Label htmlFor='username'>{t('username')}</Label>
          <Input
            type='username'
            id='username'
            name='username'
            autoComplete='username'
            placeholder={usernameText}
            minLength={minimumUsernameLength}
            required
            onChange={handleUsernameInput}
          />
          <Label htmlFor='last'>{t('password')}</Label>
          <Input
            type='password'
            id='password'
            name='password'
            autoComplete='current-password'
            placeholder={passwordText}
            minLength={minimumPasswordLength}
            required
            onChange={handlePasswordInput}
          />
        </InputText>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ButtonContainer>
          <Button kind='primary' wide={true} type='submit' disabled={disabled}>
            {t('login')}
          </Button>
        </ButtonContainer>
        <ForgotPasswordText>{t('forgotPassword')}</ForgotPasswordText>
      </Form>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  margin: 100px;
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    margin: 100px 20px;
  }
`

const Form = styled.form`
  max-width: 500px;
  margin: auto;
  text-align: center;
`

const Heading = styled(Heading1)`
  display: block;
  text-align: center;
  margin-bottom: 50px;
`

const Subheader = styled(BodyText1)`
  display: block;
  text-align: center;
  margin-bottom: 50px;
`

const Label = styled.label`
  display: none;
`

const InputText = styled(BodyText1)`
  border-color: ${({ theme }) => theme.colors.coolGray62};
`

const Input = styled.input`
  height: 56px;
  width: 400px;
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    width: 300px;
  }
  display: block;
  margin: 20px auto;
  padding: 16px;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.colors.coolGray92};
  border-style: solid;
`

const ErrorMessage = styled(BodyText3)`
  color: ${({ theme }) => theme.colors.tomatoBase};
  padding-bottom: 20px;
  display: block;
`

const ButtonContainer = styled.div`
  width: 280px;
  margin: auto;
`

const ForgotPasswordText = styled(BodyText2)`
  color: ${({ theme }) => theme.colors.coolGray44};
  display: block;
  margin-top: 40px;
  text-align: center;
`

export const FormattedLink = styled.a`
  color: ${({ theme }) => theme.colors.inkBase};
  text-decoration: underline ${({ theme }) => theme.colors.inkBase};
  &:hover {
    text-underline-offset: 5px;
  }
`

export default ResearchLoginForm


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  